/**
 * External Dependencies
 */
import 'jquery';
import Flickity from 'flickity/dist/flickity.pkgd';
require ('flickity-fullscreen/fullscreen');

$(document).ready(function() {
  $(document).on('click', '.mini-cart__close', function (e) {
    e.preventDefault();
    $('body').removeClass('cart-open');
  });

  $(document).on('click', '.mini-cart-open', function (e) {
    e.preventDefault();
    $('body').addClass('cart-open');
  });

  // SCROLLED
  $(window).on('scroll', function() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('scrolled');
    }
    else {
      $('body').removeClass('scrolled');
    }
  });

  // NEWS FILTER
  $('.products-checkbox').change(handleCatFilter);

  function handleCatFilter () {

    var uristring = '?category=';

    $('.products-checkbox:checked').each(function(index) {
      var isLastElement = index == $('.products-checkbox:checked').length -1;

      uristring += $(this).val();

      if(!isLastElement) {
        uristring += ',';
      }

    });

    window.location.href = uristring;
  }

  // Single Product trigger
  $('.close__fomo').click(function() {
    $('body').toggleClass('close-fomo');
  });

  // MENU
  $('.hamburger').click(function() {
    $(this).toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  $('.hamburger-nav-bg').click(function() {
    $('.hamburger').toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  // MOB SLIDER
  if($('.mob-slider').length) {
    const flkty = new Flickity('.mob-slider', {
      watchCSS: true,
      pageDots: true,
      prevNextButtons: false,
      wrapAround: false,
      cellAlign: 'left',
      draggable: true,
    });
  };

  // ACCORDEON
  $(function() {
    $('.accordeon > .accordeon-item.is-active').children('.accordeon-panel').slideDown();
    $('.accordeon > .accordeon-item').click(function() {
      $(this).siblings('.accordeon-item').removeClass('is-active').children('.accordeon-panel').slideUp();
      $(this).toggleClass('is-active').children('.accordeon-panel').slideToggle('ease-out');
      var $selectedTab = $(this).attr('data-item');
      console.log($selectedTab);
      $('.accordeon-image').removeClass('active');
      $('.accordeon-images').find(`.accordeon-image[data-item='${$selectedTab}']`).addClass('active');
    });
  });

  // TABNAV
  if($('.js-tabnav-tab').length) {
    (function($) {
      $.fn.tabnav = function tabnav(userConfig) {
        var config = $.extend({
          activeTabClass: 'active',
          activePanelClass: 'active',
        }, userConfig);

        var $wrapper = $(this);
        var $tabs = $wrapper.find('.js-tabnav-tab');
        var $panels = $wrapper.find('.js-tabnav-panel');
        var $panelsWrapper = $wrapper.find('.js-tabnav-panels');
        var $activeTab;
        var $activePanel;
        var activeTab = 0;

        console.log($activeTab);
        console.log(activeTab);

        $panels
        .attr('role', 'tabpanel')
        .attr('aria-hidden', 'true');

        $tabs
        .attr('role', 'tab')
        .attr('aria-selected', 'false')
        .attr('aria-setsize', $tabs.length)
        .attr('tabindex', 0)
        .each(function(i, e) {
          var $elem = $(e);
          if ($elem.hasClass(config.activeTabClass)) {
            setActiveTab(i);
          }
          $elem.attr('aria-posinset', i + 1);
        })
        .click(setTabs)
        .keypress(setTabs);

        function setActiveTab(tabPos) {
          $activeTab = $($tabs.get(tabPos))
          .addClass(config.activeTabClass)
          .attr('aria-selected', 'true');

          $activePanel = $($panels.get(tabPos))
          .addClass(config.activePanelClass)
          .attr('aria-hidden', 'false');

          $panelsWrapper.css('height', $activePanel.height() + 'px');
        }

        function setTabs(e) {
          if (e.type === 'keypress' && e.keyCode !== 13) {
            return;
          }

          var $elem = $(e.currentTarget);

          var tabPos = Number($elem.attr('aria-posinset')) - 1;
          $tabs
          .removeClass(config.activeTabClass)
          .attr('aria-selected', 'false');
          $panels
          .removeClass(config.activePanelClass)
          .attr('aria-hidden', 'true');

          setActiveTab(tabPos);
        }

        $(window).resize(function onWindowResize() {
          $panelsWrapper.css('height', $activePanel.height() + 'px');
        });

        $(window).load(function() {
          $panelsWrapper.css('height', $activePanel.height() + 'px');
        });
      }

      var tabnav = $('.tabnav');
      if (tabnav.length != 0) {
        $(tabnav).each(function(){
          $(this).tabnav();
          console.log('ok')
        });
      }
    }(jQuery));
  }

  (function() {
    var touchingCarousel = false,
      touchStartCoords;

    document.body.addEventListener('touchstart', function(e) {
      if (e.target.closest('.flickity-slider')) {
        touchingCarousel = true;
      } else {
        touchingCarousel = false;
        return;
      }

      touchStartCoords = {
        x: e.touches[0].pageX,
        y: e.touches[0].pageY
      }
    });

    document.body.addEventListener('touchmove', function(e) {
      if (!(touchingCarousel && e.cancelable)) {
        return;
      }

      var moveVector = {
        x: e.touches[0].pageX - touchStartCoords.x,
        y: e.touches[0].pageY - touchStartCoords.y
      };

      if (Math.abs(moveVector.x) > 7)
        e.preventDefault()

    }, {passive: false});
  })();

  // handle links with @href started with '#' only
  $(document).on('click', 'a[href^="#"]', function(e) {
      // target element id
      var id = $(this).attr('href');

      // target element
      var $id = $(id);
      if ($id.length === 0) {
        return;
      }

      // prevent standard hash navigation (avoid blinking in IE)
      e.preventDefault();

      // top position relative to the document
      var pos = $id.offset().top - 80;

      // animated top scrolling
      $('body, html').animate({scrollTop: pos});
  });

  // Variation Uitvoering
  const parentElement = document.getElementById('pa_uitvoering');
  const childOptions = parentElement.querySelectorAll('option');
  childOptions.forEach(element => {
    element.classList.add("enabled", "attached");
  });

});
